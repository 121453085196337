<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar card>
        <v-select
          v-model="year"
          :items="years"
          prepend-inner-icon="event"
        />
        <!--v-select
          v-model="filter"
          :items="['OneXOX','XOX']"
          label="Filter by"
          clearable
        /-->
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-sheet
          width="60px"
          color="teal"
        >
          Prepaid
        </v-sheet>
        <v-sheet
          width="60px"
          color="black"
        >
          Black
        </v-sheet>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          :total-items="12"
          :expand="expand"
          class="px-1 pb-1"
          item-key="month"
          hide-actions
        >
          <template #headers="props">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.key"
                :class="header.class"
              >
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template
            slot="items"
            slot-scope="props"
          >
            <tr @click="toggleDaily(props)">
              <td class="text-xs-center info--text">
                {{ props.item.month }}
              </td>
              <td class="text-xs-center">
                {{ formatNumber(props.item.onexPrepaid) }}
              </td>
              <td class="text-xs-center">
                {{ formatNumber(props.item.xoxPrepaid) }}
              </td>
              <td class="text-xs-center">
                {{ formatNumber(props.item.sevenEleven) }}
              </td>
              <td class="text-xs-center">
                {{ formatNumber(props.item.epay) }}
              </td>
              <td class="text-xs-center">
                {{ formatNumber(props.item.mobilityOne) }}
              </td>
              <td class="text-xs-center">
                {{ formatNumber(props.item.mol) }}
              </td>
              <td class="text-xs-center">
                {{ formatNumber(props.item.selfcare) }}
              </td>
              <td class="text-xs-center">
                {{ formatNumber(props.item.onexBlack) }}
              </td>
              <td class="text-xs-center">
                {{ formatNumber(props.item.crossTopup) }}
              </td>
              <td class="text-xs-center">
                {{ formatNumber(props.item.others) }}
              </td>
              <td class="text-xs-center info">
                {{ formatNumber(props.item.total) }}
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ noDataText }}
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              color="error"
              icon="warning"
            >
              {{ noResultText }}
            </v-alert>
          </template>
          <template #expand="props">
            <v-card flat>
              <v-card-text>
                <v-data-table
                  :items="props.item.daily"
                  :loading="loading"
                  item-key="day"
                  hide-headers
                >
                  <template
                    slot="items"
                    slot-scope="props2"
                  >
                    <td class="text-xs-center info--text">
                      {{ props2.item.day }}
                    </td>
                    <td class="text-xs-center">
                      {{ formatNumber(props2.item.onexPrepaid) }}
                    </td>
                    <td class="text-xs-center">
                      {{ formatNumber(props2.item.xoxPrepaid) }}
                    </td>
                    <td class="text-xs-center">
                      {{ formatNumber(props2.item.sevenEleven) }}
                    </td>
                    <td class="text-xs-center">
                      {{ formatNumber(props2.item.epay) }}
                    </td>
                    <td class="text-xs-center">
                      {{ formatNumber(props2.item.mobilityOne) }}
                    </td>
                    <td class="text-xs-center">
                      {{ formatNumber(props2.item.mol) }}
                    </td>
                    <td class="text-xs-center">
                      {{ formatNumber(props2.item.selfcare) }}
                    </td>
                    <td class="text-xs-center">
                      {{ formatNumber(props2.item.onexBlack) }}
                    </td>
                    <td class="text-xs-center">
                      {{ formatNumber(props2.item.crossTopup) }}
                    </td>
                    <td class="text-xs-center">
                      {{ formatNumber(props2.item.others) }}
                    </td>
                    <td class="text-xs-center info">
                      {{ formatNumber(props2.item.total) }}
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </template>
          <template slot="footer">
            <tr>
              <td class="text-xs-center">
                &nbsp;
              </td>
              <td class="text-xs-center info">
                {{ formatNumber(totals.onexPrepaid) }}
              </td>
              <td class="text-xs-center info">
                {{ formatNumber(totals.xoxPrepaid) }}
              </td>
              <td class="text-xs-center info">
                {{ formatNumber(totals.sevenEleven) }}
              </td>
              <td class="text-xs-center info">
                {{ formatNumber(totals.epay) }}
              </td>
              <td class="text-xs-center info">
                {{ formatNumber(totals.mobilityOne) }}
              </td>
              <td class="text-xs-center info">
                {{ formatNumber(totals.mol) }}
              </td>
              <td class="text-xs-center info">
                {{ formatNumber(totals.selfcare) }}
              </td>
              <td class="text-xs-center info">
                {{ formatNumber(totals.onexBlack) }}
              </td>
              <td class="text-xs-center info">
                {{ formatNumber(totals.crossTopup) }}
              </td>
              <td class="text-xs-center info">
                {{ formatNumber(totals.others) }}
              </td>
              <td class="text-xs-center info">
                {{ formatNumber(totals.total) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </wide-panel>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'
import { formatNumber } from '@/utils'

const reportTitle = 'Topup Statistics'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Finance', disabled: false, to: '/finance/finance_kits',
        },
        {
          text: 'Statistics', disabled: false, to: '/finance/statistics',
        },
        {
          text: 'Topup Statistics', disabled: true,
        },
      ],
      formatNumber: formatNumber,
      expand: false,
      filter: 'All',
      headers: [
        {
          key: 'month',
          align: 'center',
          sortable: false,
          text: 'Month',
          value: 'month',
          class: 'info--text',
        },
        {
          key: 'onexPrepaid',
          align: 'center',
          sortable: false,
          text: 'OneX Dealers',
          value: 'onexPrepaid',
          class: 'teal',
        },
        {
          key: 'xoxPrepaid',
          align: 'center',
          sortable: false,
          text: 'XOX Dealers',
          value: 'xoxPrepaid',
          class: 'teal',
        },
        {
          key: 'sevenEleven',
          align: 'center',
          sortable: false,
          text: '7-Eleven',
          value: 'sevenEleven',
          class: 'teal',
        },
        {
          key: 'epay',
          align: 'center',
          sortable: false,
          text: 'EPay',
          value: 'epay',
          class: 'teal',
        },
        {
          key: 'mobilityOne',
          align: 'center',
          sortable: false,
          text: 'Mobility One',
          value: 'mobilityOne',
          class: 'teal',
        },
        {
          key: 'mol',
          align: 'center',
          sortable: false,
          text: 'MOL',
          value: 'mol',
          class: 'teal',
        },
        {
          key: 'selfcare',
          align: 'center',
          sortable: false,
          text: 'Selfcare',
          value: 'selfcare',
          class: 'teal',
        },
        {
          key: 'onexBlack',
          align: 'center',
          sortable: false,
          text: 'OneX Dealers',
          value: 'onexBlack',
          class: 'black',
        },
        {
          key: 'crossTopup',
          align: 'center',
          sortable: false,
          text: 'XOX Dealers',
          value: 'crossTopup',
          class: 'black',
        },
        {
          key: 'others',
          align: 'center',
          sortable: false,
          text: 'Others',
          value: 'others',
          class: 'black',
        },
        {
          key: 'total',
          align: 'center',
          sortable: false,
          text: 'Total',
          value: 'total',
          class: 'info',
        },
      ],
      items: [],
      loading: false,
      menu: false,
      year: null,
      years: [],
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      showChart: false,
      title: reportTitle,
    }
  },
  computed: {
    onexox: function () {
      return this.items.filter(item => item.onex)
    },
    xox: function () {
      return this.items.filter(item => !item.onex)
    },
    all: function () {
      const all = []
      for (let i = 0; i < this.onexox.length; i++) {
        all.push({
          month: this.onexox[i].month,
          onexPrepaid: this.onexox[i].onexPrepaid + (this.xox[i] ? this.xox[i].onexPrepaid : 0),
          xoxPrepaid: this.onexox[i].xoxPrepaid + (this.xox[i] ? this.xox[i].xoxPrepaid : 0),
          sevenEleven: this.onexox[i].sevenEleven + (this.xox[i] ? this.xox[i].sevenEleven : 0),
          epay: this.onexox[i].epay + (this.xox[i] ? this.xox[i].epay : 0),
          mobilityOne: this.onexox[i].mobilityOne + (this.xox[i] ? this.xox[i].mobilityOne : 0),
          mol: this.onexox[i].mol + (this.xox[i] ? this.xox[i].mol : 0),
          selfcare: this.onexox[i].selfcare + (this.xox[i] ? this.xox[i].selfcare : 0),
          onexBlack: this.onexox[i].onexBlack + (this.xox[i] ? this.xox[i].onexBlack : 0),
          crossTopup: this.onexox[i].crossTopup + (this.xox[i] ? this.xox[i].crossTopup : 0),
          others: this.onexox[i].others + (this.xox[i] ? this.xox[i].others : 0),
          total: this.onexox[i].total + (this.xox[i] ? this.xox[i].total : 0),
        })
      }

      return all
    },
    filteredItems: function () {
      if (this.filter === 'OneXOX') return this.onexox
      else if (this.filter === 'XOX') return this.xox
      else return this.all
    },
    totals: function () {
      const items = this.filteredItems
      const totals = {
        onexPrepaid: 0,
        xoxPrepaid: 0,
        sevenEleven: 0,
        epay: 0,
        mobilityOne: 0,
        mol: 0,
        selfcare: 0,
        onexBlack: 0,
        crossTopup: 0,
        others: 0,
        total: 0,
      }

      for (const item of items) {
        totals.onexPrepaid += item.onexPrepaid
        totals.xoxPrepaid += item.xoxPrepaid
        totals.sevenEleven += item.sevenEleven
        totals.epay += item.epay
        totals.mobilityOne += item.mobilityOne
        totals.mol += item.mol
        totals.selfcare += item.selfcare
        totals.onexBlack += item.onexBlack
        totals.crossTopup += item.crossTopup
        totals.others += item.others
        totals.total += item.total
      }

      return totals
    },
  },
  watch: {
    year: function (val) {
      this.getTopupStatistics(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    const maxDate = now.toFormat('yyyy')
    for (let year = maxDate; year > 2020; year--) {
      this.years.push(year)
    }
    this.year = maxDate
  },
  methods: {
    toggleDaily (props) {
      props.expanded = !props.expanded
      if (props.expanded && !props.item.daily) {
        this.loading = true
        const params = createGetParams({
          year: this.year,
          month: props.index + 1,
        })
        this.loading = true
        this.$rest.get('getTopupStatsByMonth.php', params)
          .then(function (response) {
            this.loading = false
            props.item.daily = response.data
          }.bind(this)).catch(error => {
            this.noDataText = error.message
            this.loading = false
          })
      }
    },
    getTopupStatistics (year) {
      this.loading = true
      const params = createGetParams({
        year: year,
      })
      this.loading = true
      this.$rest.get('getTopupStatsByYear.php', params)
        .then(function (response) {
          this.items = response.data
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
